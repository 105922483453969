.tableFixHead {
    overflow-y: auto;
    min-height: 50vh;
    max-height: 80vh;
    scroll-behavior: smooth;
    padding: 0 !important;
}

.tableFixHead thead th:first-child,
.tableFixHead thead th:nth-child(2) {
    z-index: 3;
}

.tableFixHead thead th {
    position: sticky;
    top: auto;
    z-index: 1;
    font-size: 0.8em;
}

/*@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@400;700&display=swap');*/

.custom-hover td,
.custom-hover th a {
    font-size: 0.8rem;
    text-decoration: none;
    /*font-family: 'Oxygen', sans-serif;*/
    font-weight: bolder;
}

.tableFixHead tbody td,
.tableFixHead tbody th {
    font-size: 0.8em;
    text-align: center;

}

.tableFixHead tbody td div {
    border: 1px dotted transparent;
    padding: 8px 6px;
    height: 100%;
    width: 100%;
}

.tableFixHead tbody td div[contenteditable="true"] {
    border: 1px solid #2f64b1;
}

.tableFixHead tbody td {
    border: 1px dotted transparent;
    border-right: 0.5px dotted #ccc;
    border-top: 0.5px dotted #ccc;
    border-bottom: 0.5px dotted #ccc;
}


.tableFixHead thead tr:first-child th.sticky-col {
    top: 0;
    /* z-index: 3; */
}

.tableFixHead thead tr:nth-child(2) th.sticky-col {
    top: 21px;
    /* z-index: 3; */
}

.tableFixHead tbody th {
    position: sticky;
    left: 0;
}


/* .tableFixHead tbody th:not(:first-child) {
  position: sticky;
  right: 0;
} */
.tableFixHead tbody th:last-child {
    position: sticky;
    right: 0;
}

.tableFixHead tbody th:nth-last-child(-n+1) {
    position: sticky;
    right: 0;
}

/* Just common table stuff. Really. */
table * {
    box-sizing: border-box;
}

table {
    border-collapse: collapse;
    width: 100%;
}

tbody {
}

th:first-child {
    min-width: unset;
}

th,
td {
    padding: 5px 6px;
    min-width: 100px;
}

th {
    background: #eee;
    /*padding: 0 5px;*/
}

.tableFixHead .sticky-col {
    left: 0px;
    top: auto;
    width: 3em;
}

/* .tableFixHead .sticky-col::before {
  position: absolute;
  top: 0;
  bottom: -1 px;
  right: 0;
  width: 30 px;
  transform: translate(-100%);
  transition: box-shadow .3s;
  content: "";
  pointer-events: none;
  box-shadow: inset -10px 0 8px -8px #00000026;
} */


.tableFixHead thead tr:first-child .sticky-col,
.tableFixHead thead tr:first-child .sticky-col2 {
    top: 0;
}

.tableFixHead thead tr:nth-child(2) .sticky-col,
.tableFixHead thead tr:nth-child(2) .th,
.tableFixHead thead tr:nth-child(2) .sticky-col2 {
    top: 30px !important;
    box-shadow: inset 0px -100px 5px -7px #00000026;
}

.tableFixHead .sticky-col {
    padding: 10px 10px;
}

.tableFixHead .sticky-col2 {
    /* border-right: solid 1px #DDEFEF; */
    background-color: #eee;
    text-align: left;
    left: calc(2.5em);
    top: auto;
    min-width: 180px;
    border-right: 0.5px dotted #ccc;
    border-top: 0.5px dotted #ccc;
    border-bottom: 0.5px dotted #ccc;
}

.tableFixHead .sticky-col2 a::before {
    position: absolute;
    top: 0;
    /* bottom: -1px; */
    right: 0;
    height: 100%;
    /* transform: translate(-100%); */
    transition: box-shadow .3s;
    content: "";
    pointer-events: none;
    box-shadow: 0px 0px 10px 1px #00000026;
}


.tableFixHead::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.tableFixHead::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 10px;
}

.tableFixHead::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 10px;
}